export const countryRules = {
  af: { maxLength: 10, code: "AF" }, // Afeganistão (+93 XXXXXXXX)
  al: { maxLength: 12, code: "AL" }, // Albânia (+355 XXXXXXXX)
  dz: { maxLength: 11, code: "DZ" }, // Argélia (+213 XXXXXXXX)
  as: { maxLength: 11, code: "AS" }, // Samoa Americana (+1-684 XXXXXXX)
  ad: { maxLength: 10, code: "AD" }, // Andorra (+376 XXXXXXX)
  ao: { maxLength: 12, code: "AO" }, // Angola (+244 XXXXXXXX)
  ai: { maxLength: 11, code: "AI" }, // Anguilla (+1-264 XXXXXXX)
  ag: { maxLength: 11, code: "AG" }, // Antígua e Barbuda (+1-268 XXXXXXX)
  ar: { maxLength: 14, code: "AR" }, // Argentina (+54 9 XXXXXXXX)
  am: { maxLength: 11, code: "AM" }, // Armênia (+374 XXXXXXXX)
  aw: { maxLength: 10, code: "AW" }, // Aruba (+297 XXXXXXX)
  au: { maxLength: 11, code: "AU" }, // Austrália (+61 XXXXXXXXX)
  at: { maxLength: 13, code: "AT" }, // Áustria (+43 XXXXXXXX)
  az: { maxLength: 12, code: "AZ" }, // Azerbaijão (+994 XXXXXXXX)
  bs: { maxLength: 11, code: "BS" }, // Bahamas (+1-242 XXXXXXX)
  bh: { maxLength: 11, code: "BH" }, // Bahrein (+973 XXXXXXXX)
  bd: { maxLength: 13, code: "BD" }, // Bangladesh (+880 XXXXXXXX)
  bb: { maxLength: 11, code: "BB" }, // Barbados (+1-246 XXXXXXX)
  by: { maxLength: 13, code: "BY" }, // Bielorrússia (+375 XXXXXXXX)
  be: { maxLength: 12, code: "BE" }, // Bélgica (+32 XXXXXXXX)
  bz: { maxLength: 11, code: "BZ" }, // Belize (+501 XXXXXXX)
  bj: { maxLength: 11, code: "BJ" }, // Benim (+229 XXXXXXXX)
  bm: { maxLength: 11, code: "BM" }, // Bermudas (+1-441 XXXXXXX)
  bt: { maxLength: 12, code: "BT" }, // Butão (+975 XXXXXXXX)
  bo: { maxLength: 12, code: "BO" }, // Bolívia (+591 XXXXXXXX)
  ba: { maxLength: 12, code: "BA" }, // Bósnia (+387 XXXXXXXX)
  bw: { maxLength: 12, code: "BW" }, // Botsuana (+267 XXXXXXXX)
  br: { maxLength: 13, code: "BR" }, // Brasil (+55 XXXXXXXX)
  bn: { maxLength: 10, code: "BN" }, // Brunei (+673 XXXXXXX)
  bg: { maxLength: 12, code: "BG" }, // Bulgária (+359 XXXXXXXX)
  bf: { maxLength: 12, code: "BF" }, // Burquina Faso (+226 XXXXXXXX)
  bi: { maxLength: 11, code: "BI" }, // Burundi (+257 XXXXXXX)
  kh: { maxLength: 11, code: "KH" }, // Camboja (+855 XXXXXXXX)
  cm: { maxLength: 12, code: "CM" }, // Camarões (+237 XXXXXXXX)
  ca: { maxLength: 11, code: "CA" }, // Canadá (+1 XXXXXXXX)
  cv: { maxLength: 10, code: "CV" }, // Cabo Verde (+238 XXXXXXX)
  cf: { maxLength: 12, code: "CF" }, // República Centro-Africana (+236 XXXXXXXX)
  td: { maxLength: 11, code: "TD" }, // Chade (+235 XXXXXXXX)
  cl: { maxLength: 12, code: "CL" }, // Chile (+56 XXXXXXXX)
  cn: { maxLength: 13, code: "CN" }, // China (+86 XXXXXXXXXX)
  co: { maxLength: 12, code: "CO" }, // Colômbia (+57 XXXXXXXX)
  km: { maxLength: 10, code: "KM" }, // Comores (+269 XXXXXXX)
  cg: { maxLength: 11, code: "CG" }, // Congo (+242 XXXXXXXX)
  cd: { maxLength: 12, code: "CD" }, // República Democrática do Congo (+243 XXXXXXXX)
  cr: { maxLength: 11, code: "CR" }, // Costa Rica (+506 XXXXXXXX)
  ci: { maxLength: 12, code: "CI" }, // Costa do Marfim (+225 XXXXXXXX)
  hr: { maxLength: 12, code: "HR" }, // Croácia (+385 XXXXXXXX)
  cu: { maxLength: 11, code: "CU" }, // Cuba (+53 XXXXXXXX)
  cy: { maxLength: 11, code: "CY" }, // Chipre (+357 XXXXXXXX)
  cz: { maxLength: 12, code: "CZ" }, // República Tcheca (+420 XXXXXXXX)
  dk: { maxLength: 11, code: "DK" }, // Dinamarca (+45 XXXXXXXX)
  dj: { maxLength: 10, code: "DJ" }, // Djibouti (+253 XXXXXXX)
  dm: { maxLength: 11, code: "DM" }, // Dominica (+1-767 XXXXXXX)
  do: { maxLength: 11, code: "DO" }, // República Dominicana (+1-809 XXXXXXX)
  ec: { maxLength: 12, code: "EC" }, // Equador (+593 XXXXXXXX)
  eg: { maxLength: 12, code: "EG" }, // Egito (+20 XXXXXXXX)
  sv: { maxLength: 11, code: "SV" }, // El Salvador (+503 XXXXXXXX)
  gq: { maxLength: 12, code: "GQ" }, // Guiné Equatorial (+240 XXXXXXXX)
  er: { maxLength: 10, code: "ER" }, // Eritreia (+291 XXXXXXX)
  ee: { maxLength: 11, code: "EE" }, // Estônia (+372 XXXXXXXX)
  et: { maxLength: 11, code: "ET" }, // Etiópia (+251 XXXXXXXX)
  fj: { maxLength: 10, code: "FJ" }, // Fiji (+679 XXXXXXX)
  fi: { maxLength: 11, code: "FI" }, // Finlândia (+358 XXXXXXXX)
  fr: { maxLength: 12, code: "FR" }, // França (+33 XXXXXXXX)
  ga: { maxLength: 11, code: "GA" }, // Gabão (+241 XXXXXXXX)
};
